import React from 'react';

import { ReactKeycloakProvider } from '@react-keycloak/web';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Login from './components/Login/Login';
import DashboardNCR from './components/NCR/DashboardNCR/DashboardNCR';
import NCRClientDashboard from './components/NCR/NCRClientDashboard/NCRClientDashboard';
import DashboardNCRClient from './components/NCRClient/DashboardNCRClient/DashboardNCRClient';
import NotFoundPage from './components/NotFoundPage/NotFoundPage';
import { ScrollToTop } from './components/ScrollToTop/ScrollToTop';
import { ClientProvider } from './Context/ClientContextProvider';
import keycloak from './Keycloak';
import { NCRRoutes, routeType, NCRClientRoutes } from './Routes/RouteRoles';
import * as ROUTES from './Routes/Routes';

const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 600000,
        enabled: true,
      },
    },
  });

  return (
    <>
      <main>
        <ReactKeycloakProvider authClient={keycloak}>
          <Router>
            <QueryClientProvider client={queryClient}>
              <ClientProvider>
                <ScrollToTop />
                <Routes>
                  <Route path="/" element={<Login />} />
                  <Route path="*" element={<NotFoundPage />} />
                  <Route path="/ncr" element={<DashboardNCR />}>
                    {NCRRoutes.map((route) => {
                      if (route.routeType === routeType.ncr)
                        return (
                          <Route
                            key={routeType.ncr}
                            path={route.path}
                            element={route.element}
                          />
                        );
                    })}
                    {/* NESTED PROJECT ROUTES */}

                    <Route
                      path={ROUTES.CLIENT_PROFILE}
                      element={<NCRClientDashboard />}
                    >
                      {NCRRoutes.map((route, index: any) => {
                        if (route.routeType === routeType.client)
                          return (
                            <Route
                              key={routeType.client}
                              path={route.path}
                              element={route.element}
                            />
                          );
                      })}
                    </Route>
                  </Route>
                  <Route path="/dashboard" element={<DashboardNCRClient />}>
                    {NCRClientRoutes.map((route) => {
                      if (route.routeType === routeType.ncrClient)
                        return (
                          <Route
                            key={routeType.ncrClient}
                            path={route.path}
                            element={route.element}
                          />
                        );
                    })}
                  </Route>
                </Routes>
              </ClientProvider>
            </QueryClientProvider>
          </Router>
        </ReactKeycloakProvider>
      </main>
    </>
  );
};

export default App;
