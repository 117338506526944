export const sliamUploaderFileType = (fileType: string) => {
  if (fileType === 'service_contract') {
    return 'Service Contract';
  }
  if (fileType === 'asset_ib_extract') {
    return 'Asset IB Extract';
  }
  if (fileType === 'asset_geotag_scan') {
    return 'Asset GeoTag Scan';
  }
  if (fileType === 'customer_site') {
    return 'Customer Site';
  }
  if (fileType === 'AssetClassServiceMap') {
    return 'Asset Class Service Map';
  }
  if (fileType === 'auto_rule_job') {
    return 'Auto Rule Job';
  }
  return fileType;
};
