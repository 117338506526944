import React from 'react';

import { Button, Collapse } from 'antd';

type PROPS = {
  dragable: React.ReactNode;
  currentStep: number;
  inProgress: boolean;
  next: () => void;
  prev: () => void;
};

const tabs = [
  'Coverage Map',
  'Customer Service Map',
  'Customer Site Date',
  'Base Asset',
  'Geo Tag Data',
  'CFS/MCN Data Extract',
];

const Uploader = ({ dragable, currentStep, inProgress, next, prev }: PROPS) => {
  const [activeTab, setActiveTab] = React.useState(['1']);

  const handleNext = () => {
    setActiveTab([String(currentStep + 1 + 1)]);
    next();
  };
  const handlePrev = () => {
    setActiveTab([String(currentStep + 1 - 1)]);
    prev();
  };

  return (
    <>
      <Collapse
        ghost
        defaultActiveKey={activeTab}
        accordion
        expandIconPosition="end"
        activeKey={activeTab}
        className="sliamuploader-collapse"
      >
        {tabs.map((title, i) => {
          const id = String(i + 1);
          return (
            <Collapse.Panel
              collapsible={currentStep === i ? 'header' : 'disabled'}
              header={
                <div className="flex justify-start space-x-2">
                  <span
                    className={`rounded-full h-6 w-6 text-xs ${
                      activeTab[0] === id
                        ? 'bg-ncr-brand-dark-teal text-white'
                        : 'text-gray-300 border border-solid !border-gray-300'
                    } p-1 text-center`}
                  >
                    {id}
                  </span>
                  <span className="self-center">{title}</span>
                </div>
              }
              key={id}
            >
              {dragable}
            </Collapse.Panel>
          );
        })}
      </Collapse>
      <br />
      <div
        className={`flex ${
          currentStep === 0 ? 'justify-end' : 'justify-between'
        } `}
      >
        {currentStep > 0 && (
          <Button
            type="default"
            loading={inProgress}
            style={{ margin: '0 8px' }}
            onClick={handlePrev}
          >
            Previous
          </Button>
        )}
        {currentStep >= 0 && currentStep < 5 ? (
          <Button type="primary" loading={inProgress} onClick={handleNext}>
            Next
          </Button>
        ) : null}
      </div>
    </>
  );
};

export default Uploader;
