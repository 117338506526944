import React, { useEffect, useRef, useState } from 'react';

import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, notification } from 'antd';
import jwt from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

import AuthServices from '../../APIServices/AuthServices';
import smallLogo from '../../assets/images/ncr-green-logo-small.png';
import Logo from '../../assets/images/ncr_atleos_lg_rgb_pos.png';
import './Login.scss';
import { useClient } from '../../Context/ClientContextProvider';
import useAuth from '../../hooks/useAuth';

const Login = () => {
  const userRef = useRef<any>(null);
  const errRef = useRef<HTMLParagraphElement>(null);
  const navigate = useNavigate();
  const { setSingleClient, getClientById } = useClient();
  const [showRegister, setShowRegister] = useState<boolean>(false);
  const authData = useAuth();
  const { setAuth } = authData || {}; // Handle null authData
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errMsg, setErrMsg] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    const userInputNode = userRef.current;
    userInputNode?.focus();
  }, []);

  useEffect(() => {
    setErrMsg('');
  }, [username, password]);

  const handleSubmit = async () => {
    setLoading(true);
    if (!username) {
      setErrMsg('Please enter your username');
      setIsError(true);
      setLoading(false);
      errRef.current?.focus();
      return false;
    }
    if (!password) {
      setErrMsg('Please enter your password');
      setIsError(true);
      setLoading(false);
      errRef.current?.focus();
      return false;
    }
    try {
      const response = await AuthServices.loginUser({
        username,
        password,
      });
      const accessToken = response?.data?.access_token;
      const refreshToken = response?.data?.refresh_token;
      const decodedUser = jwt(accessToken);
      const roles = (decodedUser as any)?.realm_access.roles;
      const customerId = (decodedUser as any)?.customerId;
      sessionStorage.setItem('user', JSON.stringify(decodedUser));

      setAuth({
        username,
        password,
        roles,
        accessToken,
        refreshToken,
      });
      sessionStorage.setItem('accessToken', refreshToken);
      sessionStorage.setItem('refreshToken', refreshToken);
      sessionStorage.setItem('customer', customerId);
      sessionStorage.getItem('lastLocation');

      notification.close('token-expired');
      setUsername('');
      setPassword('');
      if (roles.includes('ncr-admin')) {
        if (sessionStorage.getItem('lastLocation') !== '') {
          navigate(sessionStorage.getItem('lastLocation') || '/ncr');
        } else {
          navigate('/ncr');
        }
      } else if (sessionStorage.getItem('lastLocation') !== '') {
        navigate(sessionStorage.getItem('lastLocation') || '/dashboard');
        getClientById(customerId);
      } else {
        navigate('/dashboard');
      }
    } catch (err: any) {
      console.error(err);
      if (!err?.response) {
        setErrMsg('No Server Response');
      } else if (err.response?.status === 400) {
        setErrMsg('Invalid Username or Password');
      } else if (err.response?.status === 401) {
        setErrMsg('Wrong Username or Password');
      } else {
        setErrMsg('Login Failed');
      }
      const errMsgNode = errRef.current;
      errMsgNode?.focus();
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-page" data-testid="login-page">
      <div className="logo">
        <img src={Logo} alt="NCR Logo" className="w-auto h-16" />
      </div>

      <div className="container">
        <div className="form">
          <h1 className="text-xl text-ncr-brand-dark-teal font-bold mb-4 text-center">
            Welcome to SLiAM
          </h1>
          <p
            ref={errRef}
            className={errMsg ? 'text-red-400' : 'hidden'}
            aria-live="assertive"
          >
            {errMsg}
          </p>
          <Form name="basic" autoComplete="off" onFinish={handleSubmit}>
            <Input
              placeholder="Your username"
              prefix={
                <UserOutlined className="text-ncr-brand-dark-teal font-bold" />
              }
              size="large"
              status={isError ? 'error' : ''}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              ref={userRef}
              style={{ marginBottom: '1rem' }}
            />

            <Input.Password
              placeholder="Your password"
              prefix={
                <LockOutlined className="text-ncr-brand-dark-teal font-bold" />
              }
              size="large"
              status={isError ? 'error' : ''}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{ marginBottom: '1rem' }}
            />

            <Button
              type="primary"
              htmlType="submit"
              size="large"
              className="w-full"
              loading={loading}
            >
              Login
            </Button>
          </Form>
        </div>
      </div>
      {/* <div className="banner-container">
        <img src={smallLogo} alt="" className="logo-banner" />
        <div className="banner-content">
          <h1>Transform, connect and run your technology platform.</h1>
          <h2>
            We are at the center of every integration, removing complexity and
            improving the overall experience for your entire operation.
          </h2>
        </div>
      </div> */}
    </div>
  );
};

export default Login;
