/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react';

import Icon from '@ant-design/icons';
import {
  RightSquareOutlined,
  ExclamationCircleOutlined,
  AreaChartOutlined,
  RedoOutlined,
} from '@ant-design/icons/lib/icons';
import { Button, Card, Tag } from 'antd';
import ButtonGroup from 'antd/lib/button/button-group';
import { useNavigate, useLocation } from 'react-router-dom';

import './NCRClientHome.scss';

import { useClient } from '../../../Context/ClientContextProvider';
import useAuth from '../../../hooks/useAuth';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { CustomerType } from '../../../models/CustomerType';
import NCRAssetTable from '../NCRAssetTable/NCRAssetTable';

const NCRClientHome = () => {
  const { singleClient } = useClient();
  const [activeTitle, setActiveTitle] = useState('Auto Updates');
  const [tableFilter, setTableFilter] = useState('autoUpdated=true');
  const navigate = useNavigate();
  const location = useLocation();
  const [tableTitle, setTableTitle] = useState('Auto Updates Assets');
  const [customerData, setCustomerData] = useState<CustomerType>();
  const lastSegment = location.pathname.split('/').pop();
  const axiosPrivate = useAxiosPrivate();
  const id = sessionStorage.getItem('customer');

  const getCustomerById = async () => {
    axiosPrivate({
      method: 'GET',
      url: `customers/${id}`,
    })
      .then((response: any) => {
        setCustomerData(response.data);
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getCustomerById();
  }, []);

  // const fetchAssetByStatus = async (status: string) => {
  //   axiosPrivate({
  //     method: 'GET',
  //     url: `assets?customerId=${id}&${status}`,
  //   })
  //     .then((response: any) => {
  //       if (status === 'status=PendingApproval') {
  //         setAssetPending(response.headers['x-total-count']);
  //       }
  //     })
  //     .catch((error: any) => {
  //       console.log(error);
  //     });
  // };
  //
  // useEffect(() => {
  //   fetchAssetByStatus('status=PendingApproval');
  // }, []);

  const getSingleAssetView = (assetID: string) => {
    if (lastSegment === 'overview') {
      navigate(`/ncr/customers/${id}/assets/${assetID}`);
    } else {
      navigate(`assets/${assetID}`);
    }
  };

  const setTableFilterByStatus = (status: { value: string; name: string }) => {
    setTableFilter(status.name);
    setTableTitle(status.value);
  };

  return (
    <>
      {/* <div className="dashboard-tiles">
        <Card
          title="Auto Updates"
          className={`updates-card ${
            activeTitle === 'Auto Updates'
              ? '!border-2 !border-solid !border-[#096dd9] shadow-md'
              : ''
          }`}
          onClick={() => {
            setTableFilterByStatus({
              value: 'Auto Update Assets',
              name: 'autoUpdated=true',
            });
            setActiveTitle('Auto Updates');
          }}
          extra={
            <Tag color="blue" className="custom-tag">
              <RedoOutlined />
            </Tag>
          }
        >
          <p className="status-title">
            {customerData?.autoUpdatedAssetCount === undefined
              ? 0
              : customerData?.autoUpdatedAssetCount}
          </p>
        </Card>

        <Card
          title="Auto Moved"
          className={`moved-card ${
            activeTitle === 'Auto Moved'
              ? '!border-2 !border-solid !border-[#389e0d] shadow-md'
              : ''
          }`}
          onClick={() => {
            setTableFilterByStatus({
              value: 'Auto Moved Assets',
              name: 'autoMoved=true',
            });
            setActiveTitle('Auto Moved');
          }}
          extra={
            <Tag color="green" className="custom-tag">
              <RightSquareOutlined />
            </Tag>
          }
        >
          <p className="status-title">
            {customerData?.autoMovedAssetCount === undefined
              ? 0
              : customerData?.autoMovedAssetCount}
          </p>
        </Card>
        <Card
          title="Action Required"
          className={`leaks-card ${
            activeTitle === 'Action Required'
              ? '!border-2 !border-solid !border-[#d4b106] shadow-md'
              : ''
          }`}
          onClick={() => {
            setTableFilterByStatus({
              value: 'Action Required Assets',
              name: 'actionRequired=true',
            });
            setActiveTitle('Action Required');
          }}
          extra={
            <Tag color="volcano" className="custom-tag">
              <ExclamationCircleOutlined />
            </Tag>
          }
        >
          <p className="status-title">
            {customerData?.actionRequiredAssetCount === undefined
              ? 0
              : customerData?.actionRequiredAssetCount}
          </p>
        </Card>

        <Card
          title="Total"
          onClick={() => {
            setTableFilterByStatus({
              value: 'All Assets',
              name: '',
            });
            setActiveTitle('Total');
          }}
          className={`total-card ${
            activeTitle === 'Total'
              ? '!border-2 !border-solid !border-ncr-brand-gray-700 shadow-md'
              : ''
          }`}
          extra={
            <Tag color="default" className="custom-tag">
              <AreaChartOutlined />
            </Tag>
          }
        >
          <p className="status-title">{singleClient?.assetCount}</p>
        </Card>
      </div> */}
      <div className="mt-4 mb-2 customer-overview flex justify-end">
        <ButtonGroup>
          <Button
            size="large"
            className={`flex justify-start align-middle !rounded-l-md ${
              activeTitle === 'Auto Updates'
                ? '!border !border-solid !border-[#096dd9]'
                : ''
            }`}
            onClick={() => {
              setTableFilterByStatus({
                value: 'Auto Update Assets',
                name: 'autoUpdated=true',
              });
              setActiveTitle('Auto Updates');
            }}
          >
            <span className="self-center">Auto Updates </span>
            <span className="self-center mx-2 text-ncr-brand-dark-teal px-3 py-1 text-md rounded-md bg-ncr-brand-light-teal text-center">
              {customerData?.autoUpdatedAssetCount === undefined
                ? 0
                : customerData?.autoUpdatedAssetCount}
            </span>
          </Button>
          <Button
            size="large"
            className={`flex justify-start align-middle !ml-0 ${
              activeTitle === 'Auto Moved'
                ? '!border !border-solid !border-[#389e0d]'
                : ''
            }`}
            onClick={() => {
              setTableFilterByStatus({
                value: 'Auto Moved Assets',
                name: 'autoMoved=true',
              });
              setActiveTitle('Auto Moved');
            }}
          >
            <span className="self-center">Auto Moved </span>
            <span className="self-center mx-2 text-ncr-brand-dark-teal px-3 py-1 text-md rounded-md bg-ncr-brand-light-teal text-center">
              {customerData?.autoMovedAssetCount === undefined
                ? 0
                : customerData?.autoMovedAssetCount}
            </span>
          </Button>
          <Button
            size="large"
            className={`flex justify-start align-middle !ml-0 ${
              activeTitle === 'Action Required'
                ? '!border !border-solid !border-[#d4b106]'
                : ''
            }`}
            onClick={() => {
              setTableFilterByStatus({
                value: 'Action Required Assets',
                name: 'actionRequired=true',
              });
              setActiveTitle('Action Required');
            }}
          >
            <span className="self-center">Action Required </span>
            <span className="self-center mx-2 text-ncr-brand-dark-teal px-3 py-1 text-md rounded-md bg-ncr-brand-light-teal text-center">
              {customerData?.actionRequiredAssetCount === undefined
                ? 0
                : customerData?.actionRequiredAssetCount}
            </span>
          </Button>
          <Button
            size="large"
            className={`flex justify-start align-middle !rounded-r-md !ml-0 ${
              activeTitle === 'Total'
                ? '!border !border-solid !border-ncr-brand-gray-700'
                : ''
            }`}
            onClick={() => {
              setTableFilterByStatus({
                value: 'All Assets',
                name: '',
              });
              setActiveTitle('Total');
            }}
          >
            <span className="self-center">Total</span>
            <span className="self-center mx-2 text-ncr-brand-dark-teal px-3 py-1 text-md rounded-md bg-ncr-brand-light-teal text-center">
              {singleClient?.assetCount || 0}
            </span>
          </Button>
        </ButtonGroup>
      </div>
      <NCRAssetTable
        tableTitle={tableTitle}
        tableAction={getSingleAssetView}
        isCustomer
        tableFilter={tableFilter}
      />
    </>
  );
};

export default NCRClientHome;
