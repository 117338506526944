import React from 'react';

import { CloseCircleOutlined } from '@ant-design/icons';
import { Tag, Tooltip } from 'antd';

import { SliamUploaderConstants } from '../../../utils/constants';

type Props = {
  record: any;
};

const StatusColum = ({ record }: Props) => {
  let color = 'green';
  let text;
  if (record.progressStatus === SliamUploaderConstants.STATUS.FILE_PERSISTED) {
    color = 'slateBlue';
    text = 'Accepted';
  }
  if (record.progressStatus === SliamUploaderConstants.STATUS.NON_STARTED) {
    color = 'darkGray';
    text = 'Not Started';
  }
  if (record.progressStatus === SliamUploaderConstants.STATUS.IN_PROGRESS) {
    color = 'gold';
    text = 'In Progress';
  }
  if (
    record.progressStatus === SliamUploaderConstants.STATUS.IN_PROCESS_QUEUE
  ) {
    color = 'blue';
    text = 'In Queue';
  }
  if (record.progressStatus === SliamUploaderConstants.STATUS.COMPLETED) {
    color = 'green';
    text = 'Completed';
  }
  if (record.progressStatus === SliamUploaderConstants.STATUS.ABORTED) {
    color = 'red';
    text = 'Aborted';
  }
  if (record.progressStatus === SliamUploaderConstants.STATUS.FAILED) {
    return (
      <>
        {' '}
        <Tooltip title={record?.remark}>
          <Tag color="red" icon={<CloseCircleOutlined />}>
            Failed
          </Tag>
          {record.failedCount > 0 && <Tag color="red">Failed with Errors</Tag>}
        </Tooltip>
      </>
    );
  }
  if (record.progressStatus === SliamUploaderConstants.STATUS.PAUSED) {
    color = 'orange';
    text = 'Paused';
  }
  return (
    <>
      {' '}
      <Tag color={color}>{text}</Tag>
      {record.failedCount > 0 && <Tag color="red">{text} with Errors</Tag>}
    </>
  );
};

export default StatusColum;
